// Generated by Framer (b2eeca0)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["N6UFKLFGT", "nhSn4VvyT"];

const variantClassNames = {N6UFKLFGT: "framer-v-sfbivt", nhSn4VvyT: "framer-v-eqlhw8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Variant 1": "N6UFKLFGT", "Variant 2": "nhSn4VvyT"};

const transitions = {default: {damping: 60, delay: 1, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "N6UFKLFGT", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "N6UFKLFGT", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear1xs625j = activeVariantCallback(async (...args) => {
await delay(() => setVariant("nhSn4VvyT"), 1000)
})

useOnVariantChange(baseVariant, {default: onAppear1xs625j})

const isDisplayed = () => {
if (baseVariant === "nhSn4VvyT") return false
return true
}

const isDisplayed1 = () => {
if (baseVariant === "nhSn4VvyT") return true
return false
}

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-QXXeP", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-sfbivt", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"N6UFKLFGT"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({nhSn4VvyT: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}>{isDisplayed() && (<motion.div className={"framer-1kk2fmj"} layoutDependency={layoutDependency} layoutId={"Bqb2q3F0K"} style={{backgroundColor: "rgb(0, 0, 0)"}} transition={transition}/>)}{isDisplayed1() && (<motion.div className={"framer-1xbnb3l"} layoutDependency={layoutDependency} layoutId={"vv9Bb5OjF"} style={{backgroundColor: "rgb(0, 0, 0)", opacity: 0}} transition={transition}/>)}</motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-QXXeP [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-QXXeP .framer-1ekduka { display: block; }", ".framer-QXXeP .framer-sfbivt { height: 2198px; overflow: hidden; position: relative; width: 1200px; }", ".framer-QXXeP .framer-1kk2fmj { bottom: 0px; flex: none; left: 0px; overflow: visible; position: absolute; right: 0px; top: 0px; }", ".framer-QXXeP .framer-1xbnb3l { bottom: -2198px; flex: none; height: 2198px; left: 0px; overflow: visible; position: absolute; right: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 2198
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"nhSn4VvyT":{"layout":["fixed","fixed"]}}}
 */
const FramergLbqwzN3q: React.ComponentType<Props> = withCSS(Component, css, "framer-QXXeP") as typeof Component;
export default FramergLbqwzN3q;

FramergLbqwzN3q.displayName = "Loading Animation 2";

FramergLbqwzN3q.defaultProps = {height: 2198, width: 1200};

addPropertyControls(FramergLbqwzN3q, {variant: {options: ["N6UFKLFGT", "nhSn4VvyT"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramergLbqwzN3q, [])